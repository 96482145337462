import React from "react";
import "./App.scss";

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="row min-vh-100 align-items-center">
          <div className="col-12 col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title text-left">Trustworthy</h5>
                <p className="card-text">
                  We sustain our users’ continued use of our service in
                  accessing their identity through trust, which are enabled by
                  security and privacy practices. Our practices are not shady
                  and we offer our users a sense of control to the maximum
                  extent.
                </p>
                <p>
                  Where there is a conflict between security and privacy, we
                  prioritize privacy because that’s the most respectful thing we
                  can do for Autodesk by observing users’ rights.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Familiar</h5>
                <p className="card-text">
                  As a visible component that represents Autodesk, our design
                  and interaction approaches reflect the voice of our company
                  through brand principles. While we value standards and design
                  systems, we prioritize consistency over standards to maintain
                  continuity and promote familiarity within our systems.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Aware</h5>
                <p className="card-text">
                  We build complicated systems behind the scenes, but we do not
                  let our complexities burden our users.Our users have varying
                  roles and needs; we simplify designs according to their
                  context without sacrificing necessary transparency and
                  information. At any point, we equip our users with situation
                  awareness for performing their tasks in the most efficient
                  manner, without unnecessary details.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Connected</h5>
                <p className="card-text">
                  What we develop is part of the greater ecosystem and should
                  contribute to the system at scale.Our services are bonded with
                  our partners (i.e. vendors, customers, integrating consumers)
                  to drive positive outcomes in tandem to business or user
                  objectives. There should be no isolation or silo way of
                  working to achieving end goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
